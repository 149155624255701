import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SidebarItem from './SidebarItem';
import LogoutItem from './LogoutItem';


const StyledSidebar = styled.div`
  background-color: #060B26;
  width: 15%;
  height: 98.005vh;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
`;

const Sidebar = () => {

  const location = useLocation();

  const isCurrentPage = (path) => {
    return location.pathname === path;
  };

  return (
    <StyledSidebar>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <img src="fantasy_logo_sidebar.png" alt="Fantasy Troop Logo" style={{ width: '80%', marginBottom: '3rem', alignSelf: 'baseline' }} />
      </div>
      <SidebarItem title="Dashboard" src="dashboard_icon.png" to="/dashboard" fontWeight={isCurrentPage('/dashboard') ? "bold" : "300"} />
      <SidebarItem title="Troop Members" src="settings_icon.png" to="/troop" fontWeight={isCurrentPage('/troop') ? "bold" : "300"} />
      <SidebarItem title="Marketplace" src="marketplace_icon.png" to="/marketplace" fontWeight={isCurrentPage('/marketplace') ? "bold" : "300"} />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: "200px", cursor: "pointer" }}>
        <a href="https://www.secondworld.io" target="_blank" rel="noopener noreferrer">
          <img src="help.png" alt="Fantasy Troop Logo" style={{ width: '80%', marginBottom: '3rem', alignSelf: 'baseline' }} />
        </a>
      </div>
      <LogoutItem/>
    </StyledSidebar>
  );
};

export default Sidebar;
