import React from 'react';
import styled from 'styled-components';
import CircularProgress from './CircularProgress'

const StyledTrackingCard = styled.div`
  background-color: #060B28; // Fondo oscuro
  color: #ffffff; // Texto en blanco
  padding: 20px;
  width: 40%;
  border-radius: 8px; // Esquinas redondeadas
  margin: 16px;
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataPoint = styled.div`
  margin: 10px;
`;

const ProgressContainer = styled.div`
  // Aquí puedes añadir estilos para el contenedor de la barra de progreso
`;

const Progress = styled.div`
  // Aquí puedes añadir estilos para la barra de progreso
`;

// Asegúrate de reemplazar los valores y estilos según tus necesidades
const TrackingCard = ({currentSWIOF, initialSWIOF}) => (
  <StyledTrackingCard>
    <h4 style={{ marginBottom: '2rem' }}>SWIOF Tracking</h4>
    <DataContainer>
      <div style={{marginLeft: '2rem'}}>
        <h6 style={{ color: "#A0AEC0", marginBottom: '0.5rem' }}>Current SWIOF:</h6>
        <h4 style={{ marginBottom: '2rem' }}>{currentSWIOF}</h4>
        <h6 style={{ color: "#A0AEC0", marginBottom: '0.5rem' }}>Initial SWIOF:</h6>
        <h4 style={{ marginBottom: '2rem' }}>{initialSWIOF}</h4>
      </div>        
      <CircularProgress percentage={(parseFloat(currentSWIOF)-parseFloat(initialSWIOF))/parseFloat(initialSWIOF)}/>
    </DataContainer>
  </StyledTrackingCard>
);

export default TrackingCard;
