import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SmallCard from '../components/SmallCard';
import Button from '../components/Button';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const Container = styled.div`
  background-color: #060B28;
  color: white;
  padding: 20px;
  width: 25%;
  align-items: center;
  border-radius: 12px;
  margin: auto;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: white;
`;

const Input = styled.input`
  background: transparent;
  color: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 15px;
  font-size: 16px;
  width: 92%;
  margin-bottom: 1rem;
`;

const MakeOffer = ({ data, onMakeOfferClick, memberId, totalSWIOF, leaderData, onOfferSent }) => {
  const [swiof, setSwiof] = useState('');
  const [benefit, setBenefit] = useState(5); // Valor inicial de 5%
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const validateSWIOF = () => {
    const enteredSWIOF = Number(swiof);
    const availableSWIOF = leaderData.currentSWIOF - totalSWIOF;

    if (enteredSWIOF <= 0) {
      setError('SWIOF must be a positive value.');
      setIsValid(false);
    } else if (enteredSWIOF < 5) {
      setError('SWIOF must be at least 5.');
      setIsValid(false);
    } else if (enteredSWIOF + totalSWIOF > leaderData.currentSWIOF) {
      setError(`Insufficient SWIOF. You only have ${availableSWIOF} SWIOF available.`);
      setIsValid(false);
    } else {
      setError('');
      setIsValid(true);
    }
  };

  const validateBenefit = () => {
    if (benefit <= 0 || benefit < 5) {
      setError('Benefit must be at least 5%.');
      setIsValid(false);
    } else if (benefit > 30) {
      setError('Benefit cannot exceed 30%.');
      setIsValid(false);
    } else {
      setError('');
      setIsValid(true);
    }
  };

  useEffect(() => {
    validateSWIOF();
  }, [swiof]);

  useEffect(() => {
    validateBenefit();
  }, [benefit]);

  const handleConfirm = async () => {
    if (!isValid) return;

    const auth = getAuth();
    const leaderId = auth.currentUser.uid;

    try {
      const response = await axios.post(
        'https://fantasy.secondworld.io/sendOffer/',
        {
          leaderId: leaderId,
          playerId: memberId,
          benefit: benefit,
          SWIOF: swiof 
        }
      );
      console.log(response.data);
      onOfferSent(memberId); // Notifica al componente padre
      onMakeOfferClick(''); // Cierra el popup
    } catch (error) {
      console.error('Error sending offer:', error);
    }
  };

  const remainingSWIOF = leaderData.currentSWIOF - totalSWIOF - (Number(swiof) || 0);

  return (
    <div style={{position: "absolute", alignContent: 'center', height: "100vh", width: "100%", top: 0, left: 0, background: "#00000066"}}>
      <Container>
        <div style={{width: "100%", textAlign: "right"}} onClick={() => onMakeOfferClick("")}>
          <img style={{width: "20px", cursor: "pointer"}} src="cross_icon.png" alt="Close" />
        </div>
        <div style={{width: "90%"}}>
          <SmallCard src="swiof_icon_background.png" title="Available SWIOF" number={remainingSWIOF} />
        </div>
        <div style={{paddingLeft: "32px", paddingRight: "32px"}}>
          <Label htmlFor="swiof">SWIOF Boost</Label>
          <Input
            id="swiof"
            type="number"
            value={swiof}
            onChange={(e) => setSwiof(e.target.value)}
            min="5"
            step="1"
          />
          <Label htmlFor="benefit">% Benefit for player</Label>
          <Input
            id="benefit"
            type="number"
            value={benefit}
            onChange={(e) => setBenefit(e.target.valueAsNumber)}
            min="5"
            max="30"
            step="5"
          />
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <div style={{paddingLeft: "32px", paddingRight: "32px", marginTop: "20px"}}>
          <Button text="CONFIRM" onClick={handleConfirm} disabled={!isValid} />
        </div>
      </Container>
    </div>
  );
};

export default MakeOffer;


