import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const Leaderboard = styled.div`
  background-color: #060B26;
  color: white;
  padding: 20px;
  width: 100%;
  margin: 16px;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #56577A;
  color: white;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #1B1E42;
  }
  &:nth-child(odd) {
    background-color: #060B26;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  border-radius: 50%;
`;

const PlayerEmail = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EloBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 50px;
  margin-right: 1rem;
`;

const Action = styled.div`
  color: #A0AEC0;
  cursor: pointer;
  text-align: center;
`;

const Level = styled.span`
  text-align: center;
`;

const LastBattleResult = styled.span`
  text-align: center;
  color: ${props => (props.result === 'WIN' ? 'limegreen' : 'red')};
`;

const SWIOFValue = styled.span`
  text-align: center;
  margin-right: 10px;
`;

const SWIOFInput = styled.input`
  width: 5rem;
  background-color: transparent;
  color: white; 
  border: ${props => props.editable ? '1px solid white' : 'none'}; 
  border-radius: 8px; 
  padding: 5px; 
  text-align: center; 
  margin-right: 10px;
  font-size: 16px;
`;

const BenefitInput = styled.input`
  width: 5rem;
  background-color: transparent;
  color: white; 
  border: ${props => props.editable ? '1px solid white' : 'none'}; 
  border-radius: 8px; 
  padding: 5px; 
  text-align: center; 
  margin-right: 10px;
  font-size: 16px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;

const MembersTable = ({ data, currentSWIOF, onBattleHistoryClick, updateTotalSWIOF }) => {
  const [updatedData, setUpdatedData] = useState(data);
  const [editStates, setEditStates] = useState(data.map(() => false));
  const [error, setError] = useState('');
  const [originalValues, setOriginalValues] = useState(data.map(item => ({
    initialSWIOF: item.initialSWIOF,
    benefit: item.benefit
  })));

  const handleBattleHistoryClick = (memberId) => {
    onBattleHistoryClick(memberId);
  };

  const handleInputChange = (e, index, key) => {
    const newData = [...updatedData];
    newData[index][key] = e.target.value;
    setUpdatedData(newData);

    validateSWIOF(newData);
    updateTotalSWIOF(newData);
  };

  const handleEditClick = (index) => {
    if (editStates[index]) {
      const member = updatedData[index];
      if (!validateSWIOF(updatedData)) return;
      const valid = handleUpdateMember(member.playerId, member.initialSWIOF, member.benefit, index);
      if (!valid) {
        // Restore original values if validation fails
        const newData = [...updatedData];
        newData[index].initialSWIOF = originalValues[index].initialSWIOF;
        newData[index].benefit = originalValues[index].benefit;
        setUpdatedData(newData);
        // Set error message and keep it visible for a delay
        setError('Validation failed. Values restored to original.');
        clearErrorAfterDelay(3000); // Keep error message for 3 seconds
        return;
      }
    } else {
      // Save original values when starting to edit
      const newOriginalValues = [...originalValues];
      newOriginalValues[index] = {
        initialSWIOF: updatedData[index].initialSWIOF,
        benefit: updatedData[index].benefit
      };
      setOriginalValues(newOriginalValues);
    }

    const newEditStates = [...editStates];
    newEditStates[index] = !newEditStates[index];
    setEditStates(newEditStates);
  };

  const validateSWIOF = (data) => {
    const totalSWIOF = data.reduce((sum, member) => sum + Number(member.initialSWIOF || 0), 0);
    if (totalSWIOF > currentSWIOF) {
      setError(`The total initial SWIOF (${totalSWIOF}) exceeds the current SWIOF (${currentSWIOF}).`);
      return false;
    } else {
      clearErrorAfterDelay('');
      return true;
    }
  };

  const handleUpdateMember = (playerId, initialSWIOF, benefit, index) => {
    // Validate Benefit and initialSWIOF
    if (benefit <= 0 || benefit >= 30) {
      setError('Benefit must be greater than 0 and less than 30.');
      clearErrorAfterDelay(3000); // Show error for 3 seconds
      return false; // Indicate validation failure
    }

    if (initialSWIOF <= 0) {
      setError('SWIOF must be greater than 0.');
      clearErrorAfterDelay(3000); // Show error for 3 seconds
      return false; // Indicate validation failure
    }

    // Check if Benefit and SWIOF are greater than or equal to their original values
    if (benefit < originalValues[index].benefit) {
      setError('Benefit cannot be less than the original value.');
      clearErrorAfterDelay(3000); // Show error for 3 seconds
      return false; // Indicate validation failure
    }

    if (initialSWIOF < originalValues[index].initialSWIOF) {
      setError('SWIOF cannot be less than the original value.');
      clearErrorAfterDelay(3000); // Show error for 3 seconds
      return false; // Indicate validation failure
    }

    //setError(''); // Clear any existing error message

    // Perform the update request to the server
    updateMemberOnServer(playerId, initialSWIOF, benefit);
    return true; // Indicate validation success
  };

  const clearErrorAfterDelay = (delay) => {
    setTimeout(() => {
      setError('');
    }, delay); 
  };


  const updateMemberOnServer = async (playerId, initialSWIOF, benefit) => {
    try {
      const auth = getAuth();
      const leaderId = auth.currentUser.uid;

      const response = await axios.post(
        'https://fantasy.secondworld.io/updateMember/',
        {
          leaderId,
          playerId,
          benefit,
          initialSWIOF
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error('Error updating member:', error);
    }
  };

  const handleDeleteMember = async (playerId) => {
    const auth = getAuth();
    const leaderId = auth.currentUser.uid;

    const confirmDelete = window.confirm('Are you sure you want to delete this member?');
    if (confirmDelete) {
      try {
        const response = await axios.post(
          'https://fantasy.secondworld.io/deleteMember/',
          {
            leaderId,
            playerId,
          }
        );
        console.log(response.data);
        const newData = updatedData.filter(member => member.playerId !== playerId);
        setUpdatedData(newData);
        updateTotalSWIOF(newData);
      } catch (error) {
        console.error('Error deleting member:', error);
      }
    }
  };

  useEffect(() => {
    validateSWIOF(updatedData);
  }, [updatedData]);

  return (
    <Leaderboard>
      <Table>
        <thead>
          <tr>
            <TableHeader>Avatar</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>ELO</TableHeader>
            <TableHeader>Level</TableHeader>
            <TableHeader>Initial SWIOF</TableHeader>
            <TableHeader>Current SWIOF</TableHeader>
            <TableHeader>Benefit (%)</TableHeader>
            <TableHeader>Last Battle</TableHeader>
            <TableHeader>Action</TableHeader>
          </tr>
        </thead>
        <tbody>
          {updatedData.map((item, index) => (
            <TableRow key={item.playerId}>
              <TableData><Avatar src={item.avatar} alt={`${item.email} avatar`} /></TableData>
              <TableData><PlayerEmail>{item.name}</PlayerEmail></TableData>
              <TableData>
                <EloBadge>
                  <img src={`${item.elo}.png`} alt="ELO Icon" style={{ width: '30px', height: '30px', marginRight: "5px" }} />
                  {item.elo}
                </EloBadge>
              </TableData>
              <TableData>{item.level}</TableData>
              <TableData>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <SWIOFInput
                    type="text"
                    value={item.initialSWIOF}
                    onChange={(e) => handleInputChange(e, index, 'initialSWIOF')}
                    editable={editStates[index]}
                    readOnly={!editStates[index]}
                  />
                  <img src="swiof_logo.png" alt="SWIOF Icon" style={{ width: '30px', height: '30px' }} />
                </div>
              </TableData>
              <TableData>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <SWIOFValue>{item.initialSWIOF}</SWIOFValue>
                  <img src="swiof_logo.png" alt="SWIOF Icon" style={{ width: '30px', height: '30px' }} />
                </div>
              </TableData>
              <TableData>
                <BenefitInput
                  type="text"
                  value={item.benefit}
                  onChange={(e) => handleInputChange(e, index, 'benefit')}
                  editable={editStates[index]}
                  readOnly={!editStates[index]}
                />
              </TableData>
              <TableData><LastBattleResult result={item.lastBattle}>{item.lastBattle}</LastBattleResult></TableData>
              <TableData>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <Action onClick={() => handleBattleHistoryClick(item.playerId)}>
                    <img src="battle_icon.png" alt="Battle History" style={{ height: '15px', marginRight: '5px' }} />
                    Battle History
                  </Action>
                  <Action onClick={() => handleEditClick(index)}>
                    <img 
                      src={editStates[index] ? "correct_icon.png" : "edit_icon.png"} 
                      alt={editStates[index] ? "Save Icon" : "Edit Icon"} 
                      style={{ height: '15px', marginRight: '5px' }} 
                    />
                    {editStates[index] ? "SAVE" : "EDIT"}
                  </Action>
                  <Action onClick={() => handleDeleteMember(item.playerId)}>
                    <img src="trash_icon.png" alt="Delete Icon" style={{ height: '15px', marginRight: '5px' }} />
                    DELETE MEMBER
                  </Action>
                </div>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Leaderboard>
  );
};

export default MembersTable;


