import React, { useState, useContext } from 'react';
import '../../App.css';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../../firebase';
import { UserContext } from '../../context/UserContext';

const LoginPage = ({ hideSidebar, hideSidebarPlayers }) => {

  hideSidebar();
  hideSidebarPlayers();

  const [formText, setFormText] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate(); 
  const { userId, saveUserId, clearUserId } = useContext(UserContext);


  const handleLogin = async (e) => {
    e.preventDefault(); // Evita que se recargue la página

    try {
      setFormText(""); // Limpiar cualquier mensaje anterior
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();

      // Hacer la llamada a la API
      const response = await fetch("https://fantasy.secondworld.io/authenticatePlayer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: trimmedEmail, password: trimmedPassword })
      });

      if (!response.ok) {
        // Manejar errores de la API si la respuesta no es 2xx
        const errorMessage = await response.text(); // Obtener el texto de la respuesta
        console.log("Error:", errorMessage);
        setFormText(errorMessage);
        return;
      }

      const responseData = await response.json(); // Obtener la respuesta como JSON

      console.log("responseData:", responseData);

      if (responseData.message === "Ok") {
        // Si la respuesta es "Ok", redirige a /dashboard-players
        console.log("Login exitoso");

        saveUserId(responseData.userId);

        navigate("/dashboard-players");
      } else {
        // Manejar errores de la API
        const errorMessage = responseData.message || "Error desconocido al iniciar sesión";
        console.log("Error:", errorMessage);
        setFormText(errorMessage);
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setFormText("Error de red o servidor");
    }
  };




  return (
    <div style={{ display: 'flex', height: '100vh', background: 'url("background_dashboard.png")' }}>
      {/* Columna Izquierda */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src="login_splash.jpg" alt="Fantasy Troop Splash" style={{ height: '100vh' }} /> 
      </div>

      {/* Columna Derecha */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginLeft: '7.5rem', marginRight: '2rem' }}>
        <img src="fantasy_logo.png" alt="Fantasy Troop Logo" style={{ maxWidth: '7.5rem' }} /> 
        <h2 style={{ color: 'white', marginTop: '2rem', marginBottom: '0.5rem' }}>Nice to see you!</h2>
        <p style={{ color: 'white' }}>Enter your Second World Game: New Era email and password to sign in</p>
        <form style={{ maxWidth: '30rem', marginTop: '2rem' }}> 
          <input
            type="email"
            placeholder="Your email"
            style={{
              background: 'transparent',
              color: 'white',
              border: '1px solid #ccc',
              borderRadius: '20px',
              padding: '15px',
              fontSize: '16px',
              width: '92%',
              marginBottom: '1rem',
            }}
            onChange={(e) => setEmail(e.target.value)}/>
          <input
            type="password"
            placeholder="Your password"
            style={{
              background: 'transparent',
              color: 'white',
              border: '1px solid #ccc',
              borderRadius: '20px',
              padding: '15px',
              fontSize: '16px',
              width: '92%',
              marginBottom: '1rem',
            }}
            onChange={(e) => setPassword(e.target.value)}/>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem 0' }}>
            <Button text="SIGN IN" onClick={handleLogin}/>
          </div>
          <span style={{color: "#ff2c55"}}>{formText}</span><br/>
          <div style={{ textAlign: 'center', color: 'white', textAlign: 'left', marginTop: '2rem' }}>
            Don't have a game account? <a href="https://secondworld.io/games/" style={{ color: '#add8e6' }}>Download game and create account.</a>
          </div>
        </form>
        <div style={{ marginTop: '6rem', color: 'white' }}>
          <a href="/terms" style={{ marginRight: '10px', color: '#add8e6' }}>Terms</a>
          <a href="/contact" style={{ marginRight: '10px', color: '#add8e6' }}>Contact</a>
          <a href="/privacy" style={{ color: '#add8e6' }}>Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;