import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import MembersTable from '../components/MembersTable';
import SmallCard from '../components/SmallCard';
import Button from '../components/Button';
import BattleHistory from '../components/BattleHistory';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import LoadingPlaceholder from '../components/LoadingPlaceholder'; // Importa tu nuevo componente
import SmallLoadingPlaceholder from '../components/SmallLoadingPlaceholder'; // Importa tu nuevo componente
import { UserContext } from '../context/UserContext';

const TroopPage = ({ hideSidebar, hideSidebarPlayers }) => {
  hideSidebar();
  hideSidebarPlayers();

  const navigate = useNavigate();

  const { leaderId, saveLeaderId, clearLeaderId } = useContext(UserContext);
  const [loadingLeader, setLoadingLeader] = useState(true);
  const [leaderData, setLeaderData] = useState({});
  const [totalSWIOF, setTotalSWIOF] = useState(0); // Estado para la suma de troopSWIOF
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const getLeaderById = useCallback(async () => {
    setLoadingLeader(true); // Inicia la carga
    try {
      
      const response = await axios.get(
        `https://fantasy.secondworld.io/getLeaderById/?id=${leaderId}`
      );
      console.log("leaderData", response.data);
      setLeaderData(response.data);
      if(response.data.members !== undefined){
        calculateTotalSWIOF(response.data.members);
      }else{
        setTotalSWIOF(0);
      }
    } catch (error) {
      console.error("Error al obtener el líder:", error);
    } finally {
      setLoadingLeader(false); // Finaliza la carga
    }
  }, [leaderId]);


  useEffect(() => {

    // Navega a LoginPage si no hay usuario autenticado
    if (!leaderId) {
      navigate('/');
    }

    if(leaderId){
      getLeaderById();
    }
  }, [navigate, getLeaderById]);

  // Función para calcular la suma de troopSWIOF
  const calculateTotalSWIOF = (members) => {
    if (members) {
      const sum = Object.values(members).reduce((total, member) => {
        return total + Number(member.initialSWIOF || 0);
      }, 0);
      setTotalSWIOF(sum);
    }
  };

  // Manejador de clic para "Battle History"
  const handleBattleHistoryClick = (memberId) => {
    setSelectedMemberId(memberId);
    setShowPopup(!showPopup);
  };

  const battleData = [];

  return (
    <div style={{ position: 'absolute', width: '85%', display: 'flex', flexDirection: 'column', height: '100vh', top: '0', left: '15%', background: 'url("background_dashboard.png")' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Your SWIOF" number={leaderData.initialSWIOF} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Troop's SWIOF" number={totalSWIOF} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="members_icon.png" title="Troop Members" number={leaderData.members ? Object.keys(leaderData.members).length : 0} />
        )}
      </div>
        {loadingLeader ? (
          <div>
            <h2 style={{ color: "white", marginLeft: "16px", marginTop: "16px" }}></h2>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <LoadingPlaceholder />
            </div>
          </div>
        ) : leaderData.members ? (
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: "16px", marginTop: "16px" }}>
              <img src={leaderData.emblem} alt="Emblem Icon" style={{ marginRight: "12px", width: '60px', height: '60px' }} />
              <h2 style={{ color: "white" }}>{leaderData.name}'s Troop</h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <MembersTable
                data={Object.values(leaderData.members)}
                currentSWIOF={leaderData.initialSWIOF}
                troopSWIOF={totalSWIOF}
                onBattleHistoryClick={handleBattleHistoryClick}
                updateTotalSWIOF={calculateTotalSWIOF}
              />
            </div>
          </div>
        ) : (
          null
        )}
      {showPopup && <BattleHistory data={battleData} memberId={selectedMemberId} onBattleHistoryClick={handleBattleHistoryClick} />}
    </div>
  );

};

export default TroopPage;

