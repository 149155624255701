import React from 'react';
import styled from 'styled-components';

const StyledSmallCard = styled.div`
  background-color: #060B28; // Color de fondo verde oscuro
  border-radius: 8px; // Esquinas redondeadas
  color: #ffffff; // Text en color blanco
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 16px;
`;

const Logo = styled.div`
  
`;

// Asegúrate de reemplazar 'path-to-logo.svg' con la ruta al archivo de tu logo
const SmallCard = ({src, title, number}) => (
  <StyledSmallCard>
    <div>
      <p style={{color: '#A0AEC0'}}>{title}</p>
      <h2>{number}</h2>
    </div>
    <Logo>
      <img width='60px' src={src} alt="icon" />
    </Logo>
  </StyledSmallCard>
);

export default SmallCard;
