import React from 'react';
import styled from 'styled-components';

const ProgressContainer = styled.div`
  position: relative;
  width: 50%;
  height: 50%;
`;

const ProgressCircle = styled.svg`
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: none;
  stroke-width: 4;
`;

const CircleProgress = styled.circle`
  fill: none;
  stroke: limegreen;
  stroke-width: 4;
  stroke-dasharray: 251.2; /* Circunferencia del círculo */
  stroke-dashoffset: 94.8; /* 75% de la circunferencia */
  stroke-linecap: round; /* Agrega esta línea para extremos redondeados */
`;


const ProgressText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const CircularProgress = ({ percentage }) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const offset = ((100 - percentage) / 100) * circumference;

  return (
    <ProgressContainer>
      <ProgressCircle viewBox="0 0 100 100">
        <CircleBackground cx="50" cy="50" r="40" />
        <CircleProgress cx="50" cy="50" r="40" offset={offset} />
      </ProgressCircle>
      <ProgressText>
        <div style={{fontSize: '40px', fontWeight: '900'}}>{percentage}%</div>
        <div style={{color: '#A0AEC0', fontSize: '18px', fontWeight: '300'}}>Total SWIOF Revenue</div>
      </ProgressText>
    </ProgressContainer>
  );
};

export default CircularProgress;
