import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Importa Navigate

const Container = styled.div`
  display: flex;
  align-items: center; 
  cursor: pointer;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 0.5rem; 
`;

const Text = styled.p`
  font-size: 16px;
  color: white;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: ${props => props.fontWeight}; /* Aplica la opacidad (por defecto 1 si no se proporciona) */
`;

const SidebarItem = ({title, src, to, fontWeight}) => {
  return (
    
    <StyledLink style={{textDecoration: 'none'}} to={to} fontWeight={fontWeight}>
      <Container style={{ marginBottom: '1.5rem'}}>
        <Icon src={src} alt="Icon" />
        <Text>{title}</Text>
      </Container>
    </StyledLink>
  );
};

export default SidebarItem;
