import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar'
import SidebarPlayers from './components/players/Sidebar'
import LoginPage from './pages/LoginPage';
import MobileWarning from './components/MobileWarning'; // Asegúrate de que la ruta es correcta  
import LoginPagePlayers from './pages/players/LoginPage'; 
import DashboardPage from './pages/DashboardPage'; 
import DashboardPagePlayers from './pages/players/DashboardPage'; 
import TroopPage from './pages/TroopPage'; 
import TroopPagePlayers from './pages/players/TroopPage'; 
import MarketplacePage from './pages/MarketplacePage'; 
import MarketplacePagePlayers from './pages/players/MarketplacePage'; 
import { UserProvider } from './context/UserContext';

const App = () => {

  const [showSidebar, setShowSidebar] = useState(true);
  const [showSidebarPlayers, setShowSidebarPlayers] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (isMobileDevice()) {
      setIsMobile(true);
    }
  }, []);


  return (
    <>
      {isMobile ? (
        <MobileWarning />
      ) : (
        <UserProvider>
          <Router>
            {showSidebar && <Sidebar />}
            {showSidebarPlayers && <SidebarPlayers />}
            <Routes>
              <Route path="/" exact element={<LoginPage hideSidebar={() => setShowSidebar(false)} hideSidebarPlayers={() => setShowSidebarPlayers(false)} />} />
              <Route path="/login-players" element={<LoginPagePlayers hideSidebar={() => setShowSidebar(false)} hideSidebarPlayers={() => setShowSidebarPlayers(false)} />} />
              <Route path="/dashboard" element={<DashboardPage hideSidebar={() => setShowSidebar(true)} hideSidebarPlayers={() => setShowSidebarPlayers(false)} />}/>} />
              <Route path="/dashboard-players" element={<DashboardPagePlayers hideSidebar={() => setShowSidebar(false)} hideSidebarPlayers={() => setShowSidebarPlayers(true)}/>} />
              <Route path="/troop" element={<TroopPage hideSidebar={() => setShowSidebar(true)} hideSidebarPlayers={() => setShowSidebarPlayers(false)}/>}/>} />
              <Route path="/troop-players" element={<TroopPagePlayers hideSidebar={() => setShowSidebar(false)} hideSidebarPlayers={() => setShowSidebarPlayers(true)}/>}/>} />
              <Route path="/marketplace" element={<MarketplacePage hideSidebar={() => setShowSidebar(true)} hideSidebarPlayers={() => setShowSidebarPlayers(false)}/>}/>} />
              <Route path="/marketplace-players" element={<MarketplacePagePlayers hideSidebar={() => setShowSidebar(false)} hideSidebarPlayers={() => setShowSidebarPlayers(true)}/>}/>} />
            </Routes>
          </Router>
        </UserProvider>
      )}
    </>
  );
};

export default App;
