import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background: #F53C2B;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
`;

const Button = ({ text, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      {text}
    </StyledButton>
  );
};

export default Button;