import React from 'react';
import styled from 'styled-components';

const StyledWelcomeCard = styled.div`
  background: url('welcome_img.png') no-repeat center center; 
  background-size: cover;
  border-radius: 8px; // Esquinas redondeadas
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 3rem;
  margin: 16px;
`;

// Asegúrate de reemplazar 'path-to-background-image.jpg' con la ruta al archivo de tu imagen de fondo
const WelcomeCard = ({name}) => (
  <StyledWelcomeCard>
    <h6 style={{color: "#A0AEC0", marginBottom: '0.5rem', fontWeight: '300'}}>Welcome back,</h6>
    <h1 style={{color: "white", marginBottom: '1rem'}}>{name}</h1>
    <h6 style={{color: "#A0AEC0", marginBottom: '0.5rem', fontWeight: '300'}}>Glad to see you again!</h6>
  </StyledWelcomeCard>
);

export default WelcomeCard;
