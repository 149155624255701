import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import MembersTable from '../../components/players/MembersTable';
import SmallCard from '../../components/SmallCard';
import Button from '../../components/Button';
import BattleHistory from '../../components/BattleHistory';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import axios from "axios";
import DeleteButton from "../../components/players/DeleteButton";
import { UserContext } from '../../context/UserContext';
import LoadingPlaceholder from '../../components/LoadingPlaceholder'; // Importa el componente de Loading
import SmallLoadingPlaceholder from '../../components/SmallLoadingPlaceholder'; // Importa el componente de Loading

const TroopPage = ({ hideSidebar, hideSidebarPlayers }) => {
  hideSidebar();
  hideSidebarPlayers();

  const { userId, saveUserId, clearUserId } = useContext(UserContext);
  const [leaderId, setLeaderId] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [leaderData, setLeaderData] = useState({});
  const [totalSWIOF, setTotalSWIOF] = useState(0); // Estado para la suma de troopSWIOF
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [loadingLeader, setLoadingLeader] = useState(true); // Estado de carga para líder


  const navigate = useNavigate();

  const findUserInLeaders = useCallback(async () => {
    setLoadingLeader(true);
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/findUserInLeaders/?userId=${userId}`
      );

      if (response.status === 200 && response.data.leaderId) {
        console.log("Leader ID:", response.data.leaderId);
        setLeaderId(response.data.leaderId);
      } else {
        console.error("Leader not found:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching leader data:", error);
    } finally {
      setLoadingLeader(false);
    }
  }, [userId]);

  const getUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getPlayerInfo/?userId=${userId}`
      );
      console.log("User Info", response.data);
      setUserInfo(response.data);
    } catch (error) {
      console.error("Error al obtener el usuario:", error);
    }
  }, [userId]);

  const getLeaderById = useCallback(async (id) => {
    setLoadingLeader(true);
    try {
      const response = await axios.get(`https://fantasy.secondworld.io/getLeaderById/?id=${id}`);
      console.log("leaderData", response.data);
      setLeaderData(response.data);
      calculateTotalSWIOF(response.data.members);
    } catch (error) {
      console.error("Error al obtener el líder:", error);
    } finally {
      setLoadingLeader(false);
    }
  }, []);

  

  useEffect(() => {
    // Navega a LoginPage si no hay usuario autenticado
    if (!userId) {
      navigate('/login-players');
    }

    if (userId) {
      findUserInLeaders();
      getUserInfo();
    }
    
  }, [navigate, findUserInLeaders, getUserInfo, userId]);

  useEffect(() => {
    if (leaderId) {
      console.log("leaderId: ", leaderId);
      getLeaderById(leaderId);
    }
  }, [leaderId, getLeaderById]);

  // Función para calcular la suma de troopSWIOF
  const calculateTotalSWIOF = (members) => {
    if (members) {
      const sum = Object.values(members).reduce((total, member) => {
        return total + Number(member.initialSWIOF || 0);
      }, 0);
      setTotalSWIOF(sum);
    }
  };

  const getInitialSWIOFByPlayerId = () => {
    for (const member of leaderData.members) {
      if (member.playerId === userId) {
        return member.initialSWIOF;
      }
    }
    return 0;
  };

  // Manejador de clic para "Battle History"
  const handleBattleHistoryClick = (memberId) => {
    if (memberId) {
      setSelectedMemberId(memberId);
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  const battleData = [];

  const handleLeaveTroop = async () => {
    const confirmation = window.confirm('Are you sure you want to leave this troop?');
    if (!confirmation) return; // Si el usuario cancela, termina la función.

    if (!userId || !leaderId) {
        console.error('Invalid userId or leaderId');
        return;
    }

    console.log("playerId: " + userId);
    console.log("leaderId: " + leaderId);

    try {
        const response = await axios.post(
            `https://fantasy.secondworld.io/leaveTroop/`, 
            {
                playerId: userId,
                leaderId: leaderId
            }
        );

        console.log(response.data);

        window.location.reload();

    } catch (error) {
        if (error.response) {
            console.error('Server responded with status code', error.response.status);
            console.error('Response data:', error.response.data);
            alert(`Error: ${error.response.data}`); // Muestra un mensaje de error al usuario.
        } else {
            console.error('Error making request:', error.message);
            alert(`Error: ${error.message}`); // Muestra un mensaje de error al usuario.
        }
    }
  };

  return (
    <div style={{ position: 'absolute', width: '85%', display: 'flex', flexDirection: 'column', height: '100vh', top: '0', left: '15%', background: 'url("background_dashboard.png")' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Your SWIOF" number={leaderData.members ? 0 : "Join a troop first"} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="SWIOF from Leader" number={leaderData.members ? getInitialSWIOFByPlayerId() : "Join a troop first"} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="members_icon.png" title="Troop Members" number={leaderData.members ? Object.keys(leaderData.members).length : "Join a troop first"} />
        )}
      </div>
      {!loadingLeader && leaderId ? (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: "16px", marginTop: "16px" }}>
          <img src={leaderData.emblem} alt="Emblem Icon" style={{ marginRight: "12px", width: '60px', height: '60px' }} />
          <h2 style={{ color: "white" }}>{leaderData.name}'s Troop</h2>
        </div>
      ) : (
        null
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <LoadingPlaceholder />
        ) : leaderData.members ? (
          <MembersTable
            data={Object.values(leaderData.members)}
            currentSWIOF={leaderData.currentSWIOF}
            troopSWIOF={totalSWIOF}
            onBattleHistoryClick={handleBattleHistoryClick}
            updateTotalSWIOF={calculateTotalSWIOF} // Pasa la función como prop
          />
        ) : (
          null
        )}
      </div>
      {leaderId && leaderData.members ? (
        <div style={{width: "15rem", alignSelf: "flex-end", marginRight: "16px", marginTop: "16px"}}>
          <DeleteButton text="LEAVE TROOP" onClick={handleLeaveTroop}/>
        </div>
      ) : (
        null
      )}
      {showPopup && 
        <BattleHistory
          data={battleData}
          memberId={selectedMemberId}
          onBattleHistoryClick={handleBattleHistoryClick}
        />}
    </div>
  );
};

export default TroopPage;
