import React from 'react';
import styled from 'styled-components';

const Leaderboard = styled.div`
  background-color: #0E2F2D;
  color: white;
  padding: 20px;
  width: 100%;
  margin: 16px;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #56577A;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #273C3A;
  }
  &:nth-child(odd) {
    background-color: #0E2F2D;
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const PlayerEmail = styled.span`
  text-align: left;
`;

const LastBattleResult = styled.span`
  color: ${props => (props.result === 'WIN' ? 'limegreen' : 'red')};
`;

const SWIOFValue = styled.span`
  text-align: center;
`;

const Performance = styled(SWIOFValue)`
  color: 'limegreen';
`;

const TroopTable = ({ data }) => {
  return (
    <Leaderboard>
      <Table>
        <thead>
          <tr>
            <TableHeader>Avatar</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Last Battle</TableHeader>
            <TableHeader>Initial SWIOF</TableHeader>
            <TableHeader>SWIOF Earnings</TableHeader>
            <TableHeader>Performance</TableHeader>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <TableRow key={item.email}>
              <TableData><Avatar src={item.avatar} alt={`${item.email} avatar`} /></TableData>
              <TableData><PlayerEmail>{item.name}</PlayerEmail></TableData>
              <TableData><LastBattleResult result={item.lastBattle}>{item.lastBattle}</LastBattleResult></TableData>
              <TableData>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SWIOFValue>{item.initialSWIOF}</SWIOFValue>
                  <img src="swiof_logo.png" alt="ELO Icon" style={{ marginLeft: "5px", width: '30px', height: '30px' }} />
                </div>
              </TableData>
              <TableData>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SWIOFValue>{item.currentSWIOF}</SWIOFValue>
                  <img src="swiof_logo.png" alt="ELO Icon" style={{ marginLeft: "5px", width: '30px', height: '30px' }} />
                </div>
              </TableData>
              <TableData><Performance value={0}>0%</Performance></TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Leaderboard>
  );
};

export default TroopTable;



