import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const Leaderboard = styled.div`
  background-color: #060B26;
  color: white;
  padding: 20px;
  width: 100%;
  margin: 16px;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #56577A;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #1B1E42;
  }
  &:nth-child(odd) {
    background-color: #060B26;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const EloBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Action = styled.div`
  color: #A0AEC0;
  cursor: pointer;
  text-align: center;
`;

const Level = styled.span`
  text-align: center;
`;

const LastBattleResult = styled.span`
  text-align: center;
  color: ${props => props.result === 'WIN' ? 'limegreen' : 'red'};
`;

const SWIOFValue = styled.span`
  text-align: center;
  margin-right: 10px;
`;

const SWIOFInput = styled.input`
  width: 5rem;
  background-color: transparent;
  color: white;
  border: ${props => props.editable ? '1px solid white' : 'none'};
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  font-size: 16px;
`;

const BenefitInput = styled.input`
  width: 5rem;
  background-color: transparent;
  color: white;
  border: ${props => props.editable ? '1px solid white' : 'none'};
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  margin-right: 10px;
  font-size: 16px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;

const MembersTable = ({ data, currentSWIOF, onBattleHistoryClick, updateTotalSWIOF }) => {
  const [error, setError] = useState('');

  const handleBattleHistoryClick = (memberId) => {
    onBattleHistoryClick(memberId);
  };

  return (
    <Leaderboard>
      <Table>
        <thead>
          <tr>
            <TableHeader>Avatar</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>ELO</TableHeader>
            <TableHeader>Level</TableHeader>
            <TableHeader>Initial SWIOF</TableHeader>
            <TableHeader>Current SWIOF</TableHeader>
            <TableHeader>Benefit (%)</TableHeader>
            <TableHeader>Last Battle</TableHeader>
            <TableHeader>Action</TableHeader>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableRow key={item.playerId}>
              <TableData><Avatar src={item.avatar} alt={`${item.email} avatar`} /></TableData>
              <TableData>{item.name}</TableData>
              <TableData>
                <EloBadge>
                  <img src={`${item.elo}.png`} alt="ELO Icon" style={{ width: '30px', height: '30px', marginRight: "5px" }} />
                  {item.elo}
                </EloBadge>
              </TableData>
              <TableData>{item.level}</TableData>
              <TableData>
                <SWIOFInput
                  type="text"
                  value={item.initialSWIOF}
                  editable={false}
                  readOnly={true}
                />
                <img src="swiof_logo.png" alt="SWIOF Icon" style={{ width: '30px', height: '30px', marginBottom: "-8px" }} />
              </TableData>
              <TableData>
                <SWIOFValue>{item.initialSWIOF}</SWIOFValue>
                <img src="swiof_logo.png" alt="SWIOF Icon" style={{ width: '30px', height: '30px', marginBottom: "-8px" }} />
              </TableData>
              <TableData>
                <BenefitInput
                  type="text"
                  value={item.benefit}
                  editable={false}
                  readOnly={true}
                />
              </TableData>
              <TableData><LastBattleResult result={item.lastBattle}>{item.lastBattle}</LastBattleResult></TableData>
              <TableData>
                <Action onClick={() => handleBattleHistoryClick(item.playerId)}>
                  <img src="battle_icon.png" alt="Battle History" style={{ height: '15px', marginRight: '5px' }} />
                  Battle History
                </Action>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Leaderboard>
  );
};

export default MembersTable;
