import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import TroopTable from '../components/TroopTable';
import LeadersTable from '../components/LeadersTable';
import SmallCard from '../components/SmallCard';
import WelcomeCard from '../components/WelcomeCard';
import TrackingCard from '../components/TrackingCard';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import LoadingPlaceholder from '../components/LoadingPlaceholder'; // Importa tu nuevo componente
import SmallLoadingPlaceholder from '../components/SmallLoadingPlaceholder'; // Importa tu nuevo componente
import { UserContext } from '../context/UserContext';


const DashboardPage = ({ hideSidebar, hideSidebarPlayers }) => {

  hideSidebar();
  hideSidebarPlayers();

  const navigate = useNavigate();

  const { leaderId, saveLeaderId, clearLeaderId } = useContext(UserContext);
  const [loadingLeader, setLoadingLeader] = useState(true);
  const [loadingLeaders, setLoadingLeaders] = useState(true);
  const [leaderData, setLeaderData] = useState({});
  const [totalSWIOF, setTotalSWIOF] = useState(0); // Estado para la suma de troopSWIOF
  const [leadersData, setLeadersData] = useState({});

  const getLeaderById = useCallback(async () => {
    setLoadingLeader(true); // Inicia la carga
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getLeaderById/?id=${leaderId}`
      );
      console.log("leaderData", response.data);
      setLeaderData(response.data);
      if (response.data.members !== undefined) {
        calculateTotalSWIOF(response.data.members);
      } else {
        setTotalSWIOF(0);
      }
    } catch (error) {
      console.error("Error al obtener el líder:", error);
    } finally {
      setLoadingLeader(false); // Finaliza la carga
    }
  }, [leaderId]);

  const getLeaders = useCallback(async () => {
    setLoadingLeaders(true); // Inicia la carga
    try {
      const response = await axios.get(`https://fantasy.secondworld.io/getLeaders/`);
      console.log("leadersData", response.data);
      setLeadersData(response.data);
    } catch (error) {
      console.error("Error al obtener los líderes:", error);
    } finally {
      setLoadingLeaders(false); // Finaliza la carga
    }
  }, []);


  // Función para calcular la suma de troopSWIOF
  const calculateTotalSWIOF = (members) => {
    if (members) {
      const sum = Object.values(members).reduce((total, member) => {
        return total + Number(member.initialSWIOF || 0);
      }, 0);
      setTotalSWIOF(sum);
    }
  };

  useEffect(() => {

    // Navega a LoginPage si no hay usuario autenticado
    if (!leaderId) {
      navigate('/');
    }

    if (leaderId) {

      getLeaderById();
      getLeaders();

    }
 
  }, [navigate, getLeaders, getLeaderById]);

  

  return (
    <div style={{ position: 'absolute', width: '85%', display: 'flex', flexDirection: 'column', height: '100vh', top: '0', left: '15%', background: 'url("background_dashboard.png")' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Your SWIOF" number={leaderData.initialSWIOF} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Troop's SWIOF" number={totalSWIOF} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="members_icon.png" title="Troop Members" number={leaderData.members ? Object.keys(leaderData.members).length : 0} />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <LoadingPlaceholder />
        ) : (
          <WelcomeCard name={leaderData.name} />
        )}
        {loadingLeader ? (
          <LoadingPlaceholder />
        ) : (
          <TrackingCard currentSWIOF={leaderData.initialSWIOF} initialSWIOF={leaderData.initialSWIOF} />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        { loadingLeader || leaderData.members ? (
          <h2 style={{ width: "100%", color: "white", marginLeft: "16px", marginTop: "16px" }}>Troop Deck</h2>
        ) : (
          null
        )}
        { loadingLeaders || Object.keys(leadersData).length > 0 ? (
          <h2 style={{ width: "100%", color: "white", marginLeft: "16px", marginTop: "16px" }}>Leaders Ranking</h2>
        ) : (
          null
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <LoadingPlaceholder />
        ) : leaderData.members ? (
          <TroopTable data={Object.values(leaderData.members)} />
        ) : (
          null
        )}
        {loadingLeaders ? (
          <LoadingPlaceholder />
        ) : Object.keys(leadersData).length > 0 ? (
          <LeadersTable data={Object.values(leadersData)} />
        ) : (
          null
        )}
      </div>
    </div>
  );
};

export default DashboardPage;

