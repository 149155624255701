import React, { useState, useContext } from 'react';
import '../App.css';
import { Link, useNavigate } from 'react-router-dom';
import Button from "../components/Button"
import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase';
import { UserContext } from '../context/UserContext';

const LoginPage = ({ hideSidebar, hideSidebarPlayers }) => {

  hideSidebar();
  hideSidebarPlayers();

  const [formText, setFormText] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate(); 
  const { leaderId, saveLeaderId, clearLeaderId } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault(); // Evita que se recargue la página

    try {
      setFormText("")
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();
      signInWithEmailAndPassword(auth, trimmedEmail, trimmedPassword)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);

            saveLeaderId(user.uid);

            navigate('/dashboard');

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            setFormText(errorMessage.replace('Firebase:',''))
        });
      // Lógica adicional después de crear el usuario
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      setFormText(error)
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', background: 'url("background_dashboard.png")' }}>
      {/* Columna Izquierda */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src="login_splash.jpg" alt="Fantasy Troop Splash" style={{ height: '100vh' }} /> 
      </div>

      {/* Columna Derecha */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginLeft: '7.5rem', marginRight: '2rem' }}>
        <img src="fantasy_logo.png" alt="Fantasy Troop Logo" style={{ maxWidth: '7.5rem' }} /> 
        <h2 style={{ color: 'white', marginTop: '2rem', marginBottom: '0.5rem' }}>Nice to see you!</h2>
        <p style={{ color: 'white' }}>Enter your Second World Game: New Era email and password to sign in</p>
        <form style={{ maxWidth: '30rem', marginTop: '2rem' }}> 
          <input
            type="email"
            placeholder="Your email"
            style={{
              background: 'transparent',
              color: 'white',
              border: '1px solid #ccc',
              borderRadius: '20px',
              padding: '15px',
              fontSize: '16px',
              width: '92%',
              marginBottom: '1rem',
            }}
            onChange={(e) => setEmail(e.target.value)}/>
          <input
            type="password"
            placeholder="Your password"
            style={{
              background: 'transparent',
              color: 'white',
              border: '1px solid #ccc',
              borderRadius: '20px',
              padding: '15px',
              fontSize: '16px',
              width: '92%',
              marginBottom: '1rem',
            }}
            onChange={(e) => setPassword(e.target.value)}/>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem 0' }}>
            <Button text="SIGN IN" onClick={handleLogin}/>
          </div>
          <span style={{color: "#ff2c55"}}>{formText}</span><br/>
          {/*<div style={{ textAlign: 'center', color: 'white', textAlign: 'left', marginTop: '2rem' }}>
            Don't have an account? <a href="/signup" style={{ color: '#add8e6' }}>Sign up</a>
          </div>*/}
        </form>
        <div style={{ marginTop: '6rem', color: 'white' }}>
          <a href="/terms" style={{ marginRight: '10px', color: '#add8e6' }}>Terms</a>
          <a href="/contact" style={{ marginRight: '10px', color: '#add8e6' }}>Contact</a>
          <a href="/privacy" style={{ color: '#add8e6' }}>Privacy Policy</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;