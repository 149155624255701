import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import MarketplacePlayersTable from '../../components/players/MarketplacePlayersTable';
import SmallCard from '../../components/SmallCard';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import LoadingPlaceholder from '../../components/LoadingPlaceholder'; // Importa el componente de Loading
import SmallLoadingPlaceholder from '../../components/SmallLoadingPlaceholder'; // Importa el componente de Loading
import RequestsTable from '../../components/players/RequestsTable';

const MarketplacePage = ({ hideSidebar, hideSidebarPlayers }) => {
  hideSidebar();
  hideSidebarPlayers();

  const { userId, saveUserId, clearUserId } = useContext(UserContext);
  const navigate = useNavigate();
  const [offersData, setOffersData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [leaderId, setLeaderId] = useState(null);
  const [leaderData, setLeaderData] = useState({});
  const [leadersData, setLeadersData] = useState({});
  const [totalSWIOF, setTotalSWIOF] = useState(0);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [requests, setRequests] = useState([]);
  const [loadingLeader, setLoadingLeader] = useState(true); // Estado de carga para líder
  const [loadingLeaders, setLoadingLeaders] = useState(true); // Estado de carga para líderes
  const [loadingRequests, setLoadingRequests] = useState(true); // Estado de carga para solicitudes
  const totalPages = 100;
  const [loadingOffers, setLoadingOffers] = useState(true); // Estado de carga para ofertas

  const findUserInLeaders = useCallback(async () => {
    setLoadingLeader(true);
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/findUserInLeaders/?userId=${userId}`
      );

      if (response.status === 200 && response.data.leaderId) {
        setLeaderId(response.data.leaderId);
      } else {
        console.error("Leader not found:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching leader data:", error);
    } finally {
      setLoadingLeader(false);
    }
  }, [userId]);

  const getUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getPlayerInfo/?userId=${userId}`
      );
      setUserInfo(response.data);
    } catch (error) {
      console.error("Error al obtener el usuario:", error);
    }
  }, [userId]);

  const getLeaderById = useCallback(async (id) => {
    setLoadingLeader(true);
    try {
      const response = await axios.get(`https://fantasy.secondworld.io/getLeaderById/?id=${id}`);
      setLeaderData(response.data);
      calculateTotalSWIOF(response.data.members);
    } catch (error) {
      console.error("Error al obtener el líder:", error);
    } finally {
      setLoadingLeader(false);
    }
  }, []);

  const getLeaders = useCallback(async () => {
    setLoadingLeaders(true);
    try {
      const response = await axios.get(`https://fantasy.secondworld.io/getLeaders/`);
      setLeadersData(response.data);
    } catch (error) {
      console.error("Error al obtener los líderes:", error);
    } finally {
      setLoadingLeaders(false);
    }
  }, []);

  const calculateTotalSWIOF = (members) => {
    if (members) {
      const sum = Object.values(members).reduce((total, member) => {
        return total + Number(member.initialSWIOF || 0);
      }, 0);
      setTotalSWIOF(sum);
    }
  };

  const fetchRequests = useCallback(async () => {
    setLoadingRequests(true);
    try {
      const response = await axios.get('https://fantasy.secondworld.io/getPlayerRequestsById/', {
        params: { id: userId }
      });
      const requestData = response.data?.requests || [];
      setRequests(requestData.map(req => req.requestId));
    } catch (error) {
      console.error('Error fetching requests:', error);
    } finally {
      setLoadingRequests(false);
    }
  }, [userId]);

  const getPlayerOffersById = useCallback(async () => {
    setLoadingOffers(true);
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getOffersByPlayerId/?id=${userId}`
      );
      console.log("offersData", response.data);
      setOffersData(response.data);
    } catch (error) {
      console.error("Error al obtener las ofertas:", error);
    } finally {
      setLoadingOffers(false);
    }
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      navigate('/login-players');
    } else {
      findUserInLeaders();
      getLeaders();
      getUserInfo();
      getPlayerOffersById();
      fetchRequests();
    }
  }, [navigate, findUserInLeaders, getLeaders, getUserInfo, getPlayerOffersById, fetchRequests, userId]);

  useEffect(() => {
    if (leaderId) {
      getLeaderById(leaderId);
    }
  }, [leaderId, getLeaderById]);

  const getInitialSWIOFByPlayerId = () => {
    for (const member of leaderData.members) {
      if (member.playerId === userId) {
        return member.initialSWIOF;
      }
    }
    return 0;
  };

  const handleMakeOfferClick = async (memberId) => {
    setSelectedMemberId(memberId);
    try {
      const response = await axios.post('https://fantasy.secondworld.io/sendRequest/', {
        leaderId: memberId,
        playerId: userId
      });
      // Actualiza el estado de las solicitudes al agregar la nueva solicitud enviada
      setRequests(prevRequests => [...prevRequests, memberId]);
    } catch (error) {
      console.error('Error sending offer:', error);
    }
  };

  return (
    <div style={{ position: 'absolute', width: '85%', display: 'flex', flexDirection: 'column', height: '100vh', top: '0', left: '15%', background: 'url("background_dashboard.png")' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Your SWIOF" number={leaderData.members ? 0 : "Join a troop first"} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="SWIOF from Leader" number={leaderData.members ? getInitialSWIOFByPlayerId() : "Join a troop first"} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="members_icon.png" title="Troop Members" number={leaderData.members ? Object.keys(leaderData.members).length : "Join a troop first"} />
        )}
      </div>
      <h2 style={{ color: "white", marginLeft: "16px", marginTop: "16px" }}>Marketplace</h2>
      <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeaders ? (
          <LoadingPlaceholder />
        ) : Object.keys(leadersData).length > 0 ? (
          <MarketplacePlayersTable
            userId={userId}
            data={Object.values(leadersData)}
            onMakeOfferClick={handleMakeOfferClick}
            requestsI={requests} // Pasa el estado de las solicitudes al componente de la tabla
            leaderId={leaderId !== null}
          />
        ) : (
          null
        )}
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPrevClick={() => setCurrentPage(currentPage - 1)} onNextClick={() => setCurrentPage(currentPage + 1)} />
      {(loadingOffers || loadingLeader) || (!leaderData.members && Object.keys(offersData).length > 0) ? (
        <h2 style={{ color: "white", marginLeft: "16px", marginTop: "16px" }}>Pending Requests</h2>
      ) : (
        null
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {(loadingOffers || loadingLeader) ? (
          <LoadingPlaceholder />
        ) : !leaderData.members && Object.keys(offersData).length > 0 ? (
          <RequestsTable data={Object.values(offersData)} userId={userId} />
        ) : (
          null
        )}
      </div>
    </div>
  );
};

export default MarketplacePage;

