import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const Leaderboard = styled.div`
  background-color: #060B26;
  color: white;
  padding: 20px;
  margin: 16px;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 10px;
  border-bottom: 1px solid #56577A;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #1B1E42;
  }
  &:nth-child(odd) {
    background-color: #060B26;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Button = styled.button`
  background: ${props => props.offerState === 'teamMember' ? '#FFA500' : props.offerState === 'offerMade' ? '#FFA500' : '#41C84A'};
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  cursor: ${props => props.offerState === 'teamMember' ? 'default' : 'pointer'};
  opacity: ${props => props.offerState === 'teamMember' ? 0.7 : 1};
  pointer-events: ${props => props.offerState === 'teamMember' ? 'none' : 'auto'};
  &:hover {
    opacity: ${props => props.offerState === 'teamMember' ? 0.7 : 0.8};
  }
`;

const PlayerEmail = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EloBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Action = styled.span`
  cursor: pointer;
  color: #A0AEC0;
  &:hover {
    text-decoration: underline;
  }
`;

const MarketplacePlayersTable = ({ leaderData, data, onBattleHistoryClick, onMakeOfferClick, offersI, onRemoveOfferClick }) => {
  const [offers, setOffers] = useState({});

  const fetchOffers = useCallback(async () => {
    try {
      const auth = getAuth();
      const leaderId = auth.currentUser.uid;
      const response = await axios.get(`https://fantasy.secondworld.io/getOffersByLeaderId?id=${leaderId}`);
      setOffers(response.data || {});

    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  }, []);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const handleOfferSent = (playerId) => {
    setOffers(prevOffers => ({ ...prevOffers, [playerId]: true }));
  };

  return (
    <Leaderboard>
      <Table>
        <thead>
          <tr>
            <TableHeader>Avatar</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>ELO</TableHeader>
            <TableHeader>Level</TableHeader>
            <TableHeader>Victories</TableHeader>
            <TableHeader>Battle History</TableHeader>
            <TableHeader>Make Offer</TableHeader>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            const offerMade = offers[item.playerId] !== undefined || offersI[item.playerId] !== undefined;
            const teamMember = Object.values(leaderData.members).some(player => player.playerId === item.playerId);

            return (
              <TableRow key={item.playerId}>
                <TableData><Avatar src={item.avatar} alt={`${item.email} avatar`} /></TableData>
                <TableData><PlayerEmail>{item.name}</PlayerEmail></TableData>
                <TableData>
                  <EloBadge>
                    <img src={`${item.elo}.png`} alt="ELO Icon" style={{ width: '30px', height: '30px', marginRight: "5px" }} />
                    {item.elo}
                  </EloBadge>
                </TableData>
                <TableData>{item.level}</TableData>
                <TableData style={{ color: "white" }}>{item.lastBattle}</TableData>
                <TableData>
                  <Action onClick={() => onBattleHistoryClick(item.playerId)}>
                    <img src="battle_icon.png" alt="Battle History" style={{ height: '15px', marginRight: '5px' }} />
                    Battle History
                  </Action>
                </TableData>
                <TableData>
                  <Button
                    offerState={teamMember ? 'teamMember' : offerMade ? 'offerMade' : ''}
                    onClick={() => {
                      if (!teamMember) {
                        if (!offerMade) {
                          onMakeOfferClick(item.playerId, handleOfferSent);
                        } else {
                          onRemoveOfferClick(item.playerId, handleOfferSent);
                        }
                      }
                    }}
                    disabled={teamMember}
                  >
                    {teamMember ? 'TEAM MEMBER' : offerMade ? 'REMOVE OFFER' : 'MAKE OFFER'}
                  </Button>
                </TableData>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </Leaderboard>
  );
};

export default MarketplacePlayersTable;

