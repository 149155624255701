import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(0.95);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
`;


const Placeholder = styled.div`
  background-color: rgb(6, 11, 38);
  border-radius: 8px;
  margin: 16px;
  width: 100%;
  height: 200px; /* Ajusta la altura según sea necesario */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${pulse} 1.2s infinite; /* Aplica la animación */
`;

const PlaceholderText = styled.span`
  color: white;
  font-size: 26px;
`;

const LoadingPlaceholder = () => (
  <Placeholder>
    <PlaceholderText><i>Loading...</i></PlaceholderText>
  </Placeholder>
);

export default LoadingPlaceholder;
