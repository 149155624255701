import React from 'react';
import styled from 'styled-components';

const Leaderboard = styled.div`
  background-color: #060B28;
  color: white;
  padding: 20px;
  width: 100%;
  margin: 16px;
  border-radius: 8px; // Esquinas redondeadas
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #56577A;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #1B1E42;
  }
  &:nth-child(odd) {
    background-color: #060B28;
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
`;

const Emblem = styled.img`
  width: 50px;
  height: 50px;
`;

const PlayerEmail = styled.span`
  text-align: left;
`;

const Stat = styled.span`
  text-align: center;
`;

const Performance = styled(Stat)`
  color: ${props => (props.value.startsWith('+') ? 'limegreen' : 'red')};
`;

const LeadersTable = ({ data }) => {
  return (
    <Leaderboard>
      <Table>
        <thead>
          <tr>
            <TableHeader>Emblem</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Members</TableHeader>
            <TableHeader>Initial SWIOF</TableHeader>
            <TableHeader>Current SWIOF</TableHeader>
            <TableHeader>Performance</TableHeader>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <TableRow key={item.email}>
              <TableData><Emblem src={item.emblem} alt={`${item.name} emblem`} /></TableData>
              <TableData><PlayerEmail>{item.name}</PlayerEmail></TableData>
              <TableData>{item.members}</TableData>
              <TableData>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Stat>{item.initialSWIOF}</Stat>
                  <img src="swiof_logo.png" alt="ELO Icon" style={{ marginLeft: "5px", width: '30px', height: '30px' }} />
                </div>
              </TableData>
              <TableData>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Stat>{item.initialSWIOF}</Stat>
                  <img src="swiof_logo.png" alt="ELO Icon" style={{ marginLeft: "5px", width: '30px', height: '30px' }} />
                </div>
              </TableData>
              <TableData><Performance value="0">0%</Performance></TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Leaderboard>
  );
};

export default LeadersTable;

