import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { UserContext } from '../../context/UserContext';

const Container = styled.div`
  display: flex;
  align-items: center; 
  cursor: pointer;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 0.5rem; 
`;

const Text = styled.p`
  font-size: 16px;
  color: white;
  text-decoration: underline;
`;

const LogoutItem = () => {
  const { clearUserId } = useContext(UserContext); 
  const navigate = useNavigate();

  const handleLogout = () => {
    clearUserId(); // Llama a la función para limpiar el estado del líder
    //navigate('/login-players'); // Redirige a la página de inicio
  };

  return (
    <Container style={{ marginBottom: '1rem', marginTop: '1rem' }} onClick={handleLogout}>
      <Icon src="logout_icon.png" alt="Icon" />
      <Text>Log Out</Text>
    </Container>
  );
};

export default LogoutItem;