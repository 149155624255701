import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import TroopTable from '../../components/TroopTable';
import LeadersTable from '../../components/LeadersTable';
import SmallCard from '../../components/SmallCard';
import WelcomeCard from '../../components/WelcomeCard';
import TrackingCard from '../../components/TrackingCard';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import { UserContext } from '../../context/UserContext';
import LoadingPlaceholder from '../../components/LoadingPlaceholder'; // Importa el componente de Loading
import SmallLoadingPlaceholder from '../../components/SmallLoadingPlaceholder'; // Importa el componente de Loading

const DashboardPage = ({ hideSidebar, hideSidebarPlayers }) => {
  const { userId, saveUserId, clearUserId } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({});
  const [leaderId, setLeaderId] = useState(null);
  const [leaderData, setLeaderData] = useState({});
  const [totalSWIOF, setTotalSWIOF] = useState(0); // Estado para la suma de troopSWIOF
  const [leadersData, setLeadersData] = useState({});
  const [loadingLeader, setLoadingLeader] = useState(true); // Estado de carga para líder
  const [loadingLeaders, setLoadingLeaders] = useState(true); // Estado de carga para líderes

  hideSidebar();
  hideSidebarPlayers();

  const navigate = useNavigate();

  const findUserInLeaders = useCallback(async () => {
    setLoadingLeader(true);
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/findUserInLeaders/?userId=${userId}`
      );

      if (response.status === 200 && response.data.leaderId) {
        console.log("Leader ID:", response.data.leaderId);
        setLeaderId(response.data.leaderId);
      } else {
        console.error("Leader not found:", response.data.error);
      }
    } catch (error) {
      console.error("Error fetching leader data:", error);
    } finally {
      setLoadingLeader(false);
    }
  }, [userId]);

  const getUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getPlayerInfo/?userId=${userId}`
      );
      console.log("User Info", response.data);
      setUserInfo(response.data);
    } catch (error) {
      console.error("Error al obtener el usuario:", error);
    }
  }, [userId]);

  const getLeaderById = useCallback(async (id) => {
    setLoadingLeader(true);
    try {
      const response = await axios.get(`https://fantasy.secondworld.io/getLeaderById/?id=${id}`);
      console.log("leaderData", response.data);
      setLeaderData(response.data);
      calculateTotalSWIOF(response.data.members);
    } catch (error) {
      console.error("Error al obtener el líder:", error);
    } finally {
      setLoadingLeader(false);
    }
  }, []);

  const getLeaders = useCallback(async () => {
    setLoadingLeaders(true);
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getLeaders/`
      );
      console.log("leadersData", response.data);
      setLeadersData(response.data);
    } catch (error) {
      console.error("Error al obtener los líderes:", error);
    } finally {
      setLoadingLeaders(false);
    }
  }, []);

  // Función para calcular la suma de troopSWIOF
  const calculateTotalSWIOF = (members) => {
    if (members) {
      const sum = Object.values(members).reduce((total, member) => {
        return total + Number(member.initialSWIOF || 0);
      }, 0);
      setTotalSWIOF(sum);
    }
  };

  const getInitialSWIOFByPlayerId = () => {
    for (const member of leaderData.members) {
      if (member.playerId === userId) {
        return member.initialSWIOF;
      }
    }
    return 0;
  };

  useEffect(() => {
    // Navega a LoginPage si no hay usuario autenticado
    if (!userId) {
      navigate('/login-players');
    }

    if (userId) {
      findUserInLeaders();
      getLeaders();
      getUserInfo();
    }
  }, [navigate, findUserInLeaders, getLeaders, getUserInfo]);

  useEffect(() => {
    if (leaderId) {
      console.log("leaderId: ", leaderId);
      getLeaderById(leaderId);
    }
  }, [leaderId, getLeaderById]);

  return (
    <div style={{ position: 'absolute', width: '85%', display: 'flex', flexDirection: 'column', height: '100vh', top: '0', left: '15%', background: 'url("background_dashboard.png")' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Your SWIOF" number={leaderData.members ? 0 : "Join a troop first"} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="SWIOF from Leader" number={leaderData.members ? getInitialSWIOFByPlayerId() : "Join a troop first"} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="members_icon.png" title="Troop Members" number={leaderData.members ? Object.keys(leaderData.members).length : "Join a troop first"} />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <LoadingPlaceholder />
        ) : (
          <WelcomeCard name={userInfo.name} />
        )}
        {loadingLeader ? (
          <LoadingPlaceholder />
        ) : leaderData.members ? (
          <TrackingCard currentSWIOF={0} initialSWIOF={getInitialSWIOFByPlayerId()} />
        ) : (
          <TrackingCard currentSWIOF="Join a troop first" initialSWIOF="Join a troop first" />
        )}
      </div>
      {loadingLeader || loadingLeaders || leaderId ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: "16px", marginTop: "16px" }}>
            <img src={leaderData.emblem} alt="Emblem Icon" style={{ marginRight: "12px", width: '60px', height: '60px' }} />
            <h2 style={{ color: "white" }}>{leaderData.name}'s Troop</h2>
          </div>
          <div style={{ display: 'flex', width: "50%", justifyContent: 'flex-start', alignItems: 'center', marginLeft: "16px", marginTop: "16px" }}>
            <h2 style={{ width: "100%", color: "white", marginLeft: "16px", marginTop: "16px" }}>Leaders Ranking</h2>
          </div>
        </div>
      ) : (
        <h2 style={{ width: "100%", color: "white", marginLeft: "16px", marginTop: "16px" }}>Leaders Ranking</h2>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <LoadingPlaceholder />
        ) : leaderData.members ? (
          <TroopTable data={Object.values(leaderData.members)} />
        ) : (
          null
        )}
        {loadingLeaders ? (
          <LoadingPlaceholder />
        ) : Object.keys(leadersData).length > 0 ? (
          <LeadersTable data={Object.values(leadersData)} />
        ) : (
          null
        )}
      </div>
    </div>
  );
};

export default DashboardPage;

