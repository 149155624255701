import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import MarketplacePlayersTable from '../components/MarketplacePlayersTable';
import SmallCard from '../components/SmallCard';
import Button from '../components/Button';
import BattleHistory from '../components/BattleHistory';
import MakeOffer from '../components/MakeOffer';
import Pagination from '../components/Pagination'
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import LoadingPlaceholder from '../components/LoadingPlaceholder'; // Importa tu nuevo componente
import SmallLoadingPlaceholder from '../components/SmallLoadingPlaceholder'; // Importa tu nuevo componente
import { UserContext } from '../context/UserContext';
import RequestsTable from '../components/RequestsTable';


const MarketplacePage = ({ hideSidebar, hideSidebarPlayers }) => {
  hideSidebar();
  hideSidebarPlayers();

  const navigate = useNavigate();

  const { leaderId, saveLeaderId, clearLeaderId } = useContext(UserContext);
  const [loadingLeader, setLoadingLeader] = useState(true);
  const [loadingPlayers, setLoadingPlayers] = useState(true);
  const [leaderData, setLeaderData] = useState({});
  const [loadingRequests, setLoadingRequests] = useState(true);
  const [requestsData, setRequestsData] = useState({});
  const [membersData, setMembersData] = useState({});
  const [totalSWIOF, setTotalSWIOF] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [showOfferPopup, setShowOfferPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 100;
  const [offers, setOffers] = useState({});

  const getLeaderById = useCallback(async () => {
    setLoadingLeader(true); // Inicia la carga
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getLeaderById/?id=${leaderId}`
      );
      console.log("leaderData", response.data);
      setLeaderData(response.data);
      if (response.data.members !== undefined) {
        calculateTotalSWIOF(response.data.members);
      } else {
        setTotalSWIOF(0);
      }
    } catch (error) {
      console.error("Error al obtener el líder:", error);
    } finally {
      setLoadingLeader(false); // Finaliza la carga
    }
  }, [leaderId]);

  const getRequestsById = useCallback(async () => {
    setLoadingRequests(true); // Inicia la carga
    try {
      const response = await axios.get(
        `https://fantasy.secondworld.io/getRequestsById/?id=${leaderId}`
      );
      console.log("requestsData", response.data);
      setRequestsData(response.data);
    } catch (error) {
      console.error("Error al obtener las requests:", error);
    } finally {
      setLoadingRequests(false); // Finaliza la carga
    }
  }, [leaderId]);

  const getPlayersMarketplace = useCallback(async () => {
    setLoadingPlayers(true); // Inicia la carga
    try {
      const leagueId = 0;
      const subLeagueId = 0;
      const response = await axios.get(
        `https://fantasy.secondworld.io/getPlayersMarketplace/?leagueId=${leagueId}&subLeagueId=${subLeagueId}`
      );
      console.log("membersData", response.data);
      setMembersData(response.data);
    } catch (error) {
      console.error("Error al obtener los jugadores del mercado:", error);
    } finally {
      setLoadingPlayers(false); // Finaliza la carga
    }
  }, []);


  // Función para calcular la suma de troopSWIOF
  const calculateTotalSWIOF = (members) => {
    if (members) {
      const sum = Object.values(members).reduce((total, member) => {
        return total + Number(member.initialSWIOF || 0);
      }, 0);
      setTotalSWIOF(sum);
    }
  };

  useEffect(() => {

    // Navega a LoginPage si no hay usuario autenticado
    if (!leaderId) {
      navigate('/');
    }

    if(leaderId){
      getLeaderById();
      getRequestsById();
      getPlayersMarketplace();
    }
  }, [navigate, getLeaderById, getRequestsById, getPlayersMarketplace]);

  // Función para manejar el evento cuando se envía una oferta
  const handleOfferSent = (playerId) => {
    setOffers(prevOffers => ({ ...prevOffers, [playerId]: true }));
  };

  // Manejador de clic para "Battle History"
  const handleBattleHistoryClick = (memberId) => {
    setSelectedMemberId(memberId);
    setShowPopup(!showPopup);
  };

  // Manejador de clic para "Make Offer"
  const handleMakeOfferClick = (memberId, handleOfferSent) => {
    console.log("memberId" + memberId);
    setSelectedMemberId(memberId);
    setShowOfferPopup(!showOfferPopup);
  };

  // Manejador de clic para "Remove Offer"
  const handleRemoveOfferClick = async (memberId) => {
    const confirmDelete = window.confirm('Are you sure you want to remove this offer?');
    
    if (confirmDelete) {
      console.log("memberId" + memberId);
      setSelectedMemberId(memberId);

      try {
        const response = await axios.post(
          'https://fantasy.secondworld.io/removeOffer/',
          {
            leaderId: leaderId,
            playerId: memberId
          }
        );
        console.log(response.data);
        window.location.reload(); 
      } catch (error) {
        console.error('Error sending offer:', error);
      }
    }
  };


  const battleData = [];

  return (
    <div style={{ position: 'absolute', width: '85%', display: 'flex', flexDirection:'column', height: '100vh', top: '0', left: '15%', background: 'url("background_dashboard.png")' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Your SWIOF" number={leaderData.initialSWIOF} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="swiof_icon_background.png" title="Troop's SWIOF" number={totalSWIOF} />
        )}
        {loadingLeader ? (
          <SmallLoadingPlaceholder />
        ) : (
          <SmallCard src="members_icon.png" title="Troop Members" number={leaderData.members ? Object.keys(leaderData.members).length : 0} />
        )}
      </div>
      <h2 style={{ color: "white", marginLeft: "16px", marginTop: "16px" }}>Marketplace</h2>
      <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {loadingLeader || loadingPlayers ? (
          <LoadingPlaceholder />
        ) : (Object.keys(membersData).length > 0 && leaderData) ? (
          <MarketplacePlayersTable 
            leaderData={leaderData}
            data={membersData} 
            onBattleHistoryClick={handleBattleHistoryClick} 
            onMakeOfferClick={handleMakeOfferClick}
            onRemoveOfferClick={handleRemoveOfferClick}
            offersI={offers} // Pasa el estado de las ofertas como prop
          />
        ) : (
          null
        )}
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPrevClick={() => setCurrentPage(currentPage - 1)} onNextClick={() => setCurrentPage(currentPage + 1)} />
      {loadingRequests ? (
        <div>
          <h2 style={{ color: "white", marginLeft: "16px", marginTop: "16px" }}>Pending Requests</h2>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <LoadingPlaceholder />
          </div>
        </div>
      ) : Object.keys(requestsData).length > 0 ? (
        <div>
          <h2 style={{ color: "white", marginLeft: "16px", marginTop: "16px" }}>Pending Requests</h2>
          <RequestsTable data={Object.values(requestsData)} onBattleHistoryClick={handleBattleHistoryClick} />
        </div>
      ) : (
        null
      )}
      {showPopup && <BattleHistory data={battleData} memberId={selectedMemberId} onBattleHistoryClick={handleBattleHistoryClick} />}
      {showOfferPopup && 
        <MakeOffer 
          data={membersData} 
          onMakeOfferClick={handleMakeOfferClick} 
          memberId={selectedMemberId}
          totalSWIOF={totalSWIOF}
          leaderData={leaderData}
          onOfferSent={handleOfferSent} // Pasa la función handleOfferSent
        />
      }
    </div>
  );

};

export default MarketplacePage;