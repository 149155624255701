import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Leaderboard = styled.div`
  background-color: #060B28;
  color: white;
  padding: 20px;
  width: 25%;
  align-items: center;
  border-radius: 12px;
  margin: auto;
`;

const RankRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #56577A;
`;

const LastBattleResult = styled.span`
  width: 100px;
  text-align: center;
  color: ${props => (props.result === 'WIN' ? 'limegreen' : 'red')};
`;

const LastBattleText = styled.span`
  width: 100px;
  text-align: center;
  color: white;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
  text-align: right;
  align-items: center;
`;

const BattleHistory = ({ memberId, onBattleHistoryClick }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchBattles = async () => {
      try {
        const response = await axios.get(
          `https://fantasy.secondworld.io/getPlayerBattles/?userId=${memberId}`
        );
        setData(response.data);
        console.log("response.data", response.data);
      } catch (error) {
        console.error('Error fetching battle history:', error);
      } finally {
        setLoading(false);
      }
    };

    if (memberId) {
      fetchBattles();
    }
  }, [memberId]);

  return (
    <div style={{ position: "absolute", alignContent: 'center', height: "100vh", width: "100%", top: 0, left: 0, background: "#00000066" }}>
      <Leaderboard>
        <div style={{ width: "100%", textAlign: "right" }} onClick={() => onBattleHistoryClick(null)}>
          <img style={{ width: "20px", cursor: "pointer" }} src="cross_icon.png" alt="Close" />
        </div>
        <TableHeader>
          <LastBattleResult style={{ color: "white" }} result="Last Battle">Battle History</LastBattleResult>
        </TableHeader>
        <Line />
        {loading ? (
          <div>
            <RankRow>
              <LastBattleText>
                Loading...
              </LastBattleText>
            </RankRow>
          </div>
        ) : (
          data.length > 0 ? (
            data.map((item, index) => (
              <div key={index}>
                <RankRow>
                  <LastBattleResult result={item.winner ? 'WIN' : 'LOSE'}>
                    {item.winner ? 'WIN' : 'LOSE'}
                  </LastBattleResult>
                </RankRow>
                <Line />
              </div>
            ))
          ) : (
            <div>
              <RankRow>
                <LastBattleText>
                  There are no battles
                </LastBattleText>
              </RankRow>
            </div>
          )
        )}
      </Leaderboard>
    </div>
  );
};

export default BattleHistory;