import React from 'react';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowButton = styled.button`
  background: #41C84A;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 12px;
`;

const PageNumber = styled.span`
  color: white;
  margin: 0 1rem;
  font-size: 1.2rem;
`;

const Pagination = ({ currentPage, totalPages, onPrevClick, onNextClick }) => {
  return (
    <PaginationContainer>
      <ArrowButton onClick={onPrevClick}>←</ArrowButton>
      <PageNumber>{currentPage} / {totalPages}</PageNumber>
      <ArrowButton onClick={onNextClick}>→</ArrowButton>
    </PaginationContainer>
  );
};

export default Pagination;
