import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Leaderboard = styled.div`
  background-color: #060B26;
  color: white;
  padding: 20px;
  width: 100%;
  margin: 16px;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #56577A;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #1B1E42;
  }
  &:nth-child(odd) {
    background-color: #060B26;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 1rem;
`;

const PlayerEmail = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Action = styled.div`
  color: #A0AEC0;
  cursor: pointer;
  text-align: center;
`;

const Benefit = styled.span`
  text-align: center;
`;

const Stat = styled.span`
  text-align: center;
`;

const RequestsTable = ({ data, userId }) => {
  const [updatedData, setUpdatedData] = useState(data);

  const handleAccept = async (item, index) => {
    try {
      await axios.post(
        `https://fantasy.secondworld.io/acceptOffer`, 
        {
          leaderId: item.leaderId,
          playerId: userId,
          benefit: item.benefit,
          SWIOF: item.SWIOF
        }
      );

      const newData = [...updatedData];
      newData.splice(index, 1);
      setUpdatedData(newData);

      window.location.reload();
      
    } catch (error) {
      console.error('Error accepting request:', error.message);
    }
  };

  const handleReject = async (item, index) => {
    try {
      await axios.post(
        `https://fantasy.secondworld.io/rejectOffer`, 
        {
          playerId: userId,
          leaderId: item.leaderId
        }
      );

      const newData = [...updatedData];
      newData.splice(index, 1);
      setUpdatedData(newData);
    } catch (error) {
      console.error('Error rejecting request:', error.message);
    }
  };

  return (
    <Leaderboard>
      <Table>
        <thead>
          <tr>
            <TableHeader>Avatar</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>SWIOF</TableHeader>
            <TableHeader>Benefit</TableHeader>
            <TableHeader>Actions</TableHeader>
          </tr>
        </thead>
        <tbody>
          {updatedData.map((item, index) => (
            <TableRow key={item.leaderId}>
              <TableData><Avatar src={item.leaderData.emblem} alt={`${item.leaderData.email} emblem`} /></TableData>
              <TableData>{item.leaderData.name}</TableData>
              <TableData>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Stat>{item.SWIOF}</Stat>
                  <img src="swiof_logo.png" alt="SWIOF Icon" style={{ marginLeft: "5px", width: '30px', height: '30px' }} />
                </div>
              </TableData>
              <TableData><Benefit>{item.benefit}%</Benefit></TableData>
              <TableData>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <Action style={{ color: '#48E80F' }} onClick={() => handleAccept(item, index)}>
                    <img src="correct_icon.png" alt="Correct Icon" style={{ height: '15px', marginRight: '5px' }} />
                    ACCEPT
                  </Action>
                  <Action style={{ color: '#F53C2B' }} onClick={() => handleReject(item, index)}>
                    <img src="trash_icon.png" alt="Reject Icon" style={{ height: '15px', marginRight: '5px' }} />
                    REJECT
                  </Action>
                </div>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Leaderboard>
  );
};

export default RequestsTable;
