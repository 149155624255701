// MobileWarning.js
import React from 'react';
import styled from 'styled-components';

const MobileWarningContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 9999;
  background: url("background_dashboard.png");
  object-fit: contain;
`;

const MobileWarningText = styled.p`
  font-size: 30px;
  text-align: center;
  padding: 20px;
`;

const MobileWarning = () => {
  return (
    <MobileWarningContainer>
      <MobileWarningText>
        This application is best viewed on a PC. Please open it from a desktop computer.
      </MobileWarningText>
    </MobileWarningContainer>
  );
};

export default MobileWarning;
