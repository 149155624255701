import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getAuth } from "firebase/auth";

const Leaderboard = styled.div`
  background-color: #060B26;
  color: white;
  padding: 20px;
  margin: 16px;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 10px;
  border-bottom: 1px solid #56577A;
  text-align: center;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #1B1E42;
  }
  &:nth-child(odd) {
    background-color: #060B26;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TableData = styled.td`
  padding: 10px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const EloBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Action = styled.div`
  color: #A0AEC0;
  cursor: pointer;
  text-align: center;
`;

const Level = styled.span`
  text-align: center;
`;

const LastBattleResult = styled.span`
  text-align: center;
  color: ${props => (props.result === 'WIN' ? 'limegreen' : 'red')};
`;

const Benefit = styled.span`
  text-align: center;
`;

const RequestsTable = ({ data, onBattleHistoryClick }) => {
  const [updatedData, setUpdatedData] = useState(data);

  const handleAccept = async (item, index) => {
    try {
      const auth = getAuth();
      const leaderId = auth.currentUser.uid;

      const response = await axios.post(
        `https://fantasy.secondworld.io/acceptRequest`, 
        {
          requestId: item.requestId,
          leaderId: leaderId,
          playerId: item.playerId,
          benefit: item.benefit
        }
      );

      const newData = [...updatedData];
      newData.splice(index, 1);
      setUpdatedData(newData);

      window.location.reload();
    } catch (error) {
      console.error('Error accepting request:', error.message);
    }
  };

  const handleReject = async (item, index) => {
    try {
      const auth = getAuth();
      const leaderId = auth.currentUser.uid;

      const response = await axios.post(
        `https://fantasy.secondworld.io/rejectRequest`, 
        {
          requestId: item.requestId,
          leaderId: leaderId
        }
      );

      const newData = [...updatedData];
      newData.splice(index, 1);
      setUpdatedData(newData);
      window.location.reload()
    } catch (error) {
      console.error('Error rejecting request:', error.message);
    }
  };

  return (
    <Leaderboard>
      <Table>
        <thead>
          <tr>
            <TableHeader>Avatar</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>ELO</TableHeader>
            <TableHeader>Level</TableHeader>
            <TableHeader>Benefit (%)</TableHeader>
            <TableHeader>Last Battle</TableHeader>
            <TableHeader>Actions</TableHeader>
          </tr>
        </thead>
        <tbody>
          {updatedData.map((item, index) => (
            <TableRow key={item.playerId}>
              <TableData><Avatar src={item.avatar} alt={`${item.email} avatar`} /></TableData>
              <TableData>{item.name}</TableData>
              <TableData>
                <EloBadge>
                  <img src={`${item.elo}.png`} alt="ELO Icon" style={{ width: '30px', height: '30px', marginRight: "5px" }} />
                  {item.elo}
                </EloBadge>
              </TableData>
              <TableData>{item.level}</TableData>
              <TableData>{item.benefit}%</TableData>
              <TableData><LastBattleResult result={item.lastBattle}>{item.lastBattle}</LastBattleResult></TableData>
              <TableData>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <Action onClick={() => onBattleHistoryClick(item.playerId)}>
                    <img src="battle_icon.png" alt="Battle History" style={{ height: '15px', marginRight: '5px' }} />
                    Battle History
                  </Action>
                  <Action style={{ color: '#48E80F' }} onClick={() => handleAccept(item, index)}>
                    <img src="correct_icon.png" alt="Accept" style={{ height: '15px', marginRight: '5px' }} />
                    Accept
                  </Action>
                  <Action style={{ color: '#F53C2B' }} onClick={() => handleReject(item, index)}>
                    <img src="trash_icon.png" alt="Reject" style={{ height: '15px', marginRight: '5px' }} />
                    Reject
                  </Action>
                </div>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Leaderboard>
  );
};

export default RequestsTable;