// UserContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';

// Crear el contexto
export const UserContext = createContext(null);

// Crear el proveedor
export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(() => {
    // Intentar recuperar el userId de localStorage
    return localStorage.getItem('userId') || null;
  });

  // Función para establecer el userId
  const saveUserId = useCallback((id) => {
    setUserId(id);
    localStorage.setItem('userId', id);
  }, []);

  // Función para eliminar el userId
  const clearUserId = useCallback(() => {
    setUserId(null);
    localStorage.removeItem('userId');
  }, []);


  const [leaderId, setLeaderId] = useState(() => {
    // Intentar recuperar el userId de localStorage
    return localStorage.getItem('leaderId') || null;
  });

  // Función para establecer el userId
  const saveLeaderId = useCallback((id) => {
    setLeaderId(id);
    localStorage.setItem('leaderId', id);
  }, []);

  // Función para eliminar el userId
  const clearLeaderId = useCallback(() => {
    setLeaderId(null);
    localStorage.removeItem('leaderId');
  }, []);

  // Proveer el contexto
  return (
    <UserContext.Provider value={{ userId, saveUserId, clearUserId, leaderId, saveLeaderId, clearLeaderId }}>
      {children}
    </UserContext.Provider>
  );
};
