import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Leaderboard = styled.div`
  background-color: #060B26;
  color: white;
  padding: 20px;
  margin: 16px;
  border-radius: 8px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 200px 200px 200px 200px 300px;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;

const Emblem = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Header = styled(Grid)`
  height: 50px;
  font-weight: bold;
  border-bottom: 1px solid #56577A;
`;

const Row = styled(Grid)`
  padding: 10px 0;
  border-bottom: 1px solid #56577A;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Button = styled.button`
  background: ${props => props.offerState === 'inTroop' ? '#FFA500' : props.offerState === 'requestSent' ? '#FFA500' : '#41C84A'};
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  cursor: ${props => props.offerState === 'inTroop' || props.offerState === 'requestSent' ? 'default' : 'pointer'};
  opacity: ${props => props.offerState === 'inTroop' || props.offerState === 'requestSent' ? 0.7 : 1};
  pointer-events: ${props => props.offerState === 'inTroop' || props.offerState === 'requestSent' ? 'none' : 'auto'};
  &:hover {
    opacity: ${props => props.offerState === 'inTroop' || props.offerState === 'requestSent' ? 0.7 : 0.8};
  }
`;

const PlayerEmail = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Stat = styled.div`
  display: flex;
  align-items: center;
`;

const Performance = styled(Stat)`
  color: ${props => props.value.startsWith('+') ? 'limegreen' : 'red'};
`;

const MarketplacePlayersTable = ({ userId, data, onMakeOfferClick, requestsI, leaderId }) => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.get('https://fantasy.secondworld.io/getPlayerRequestsById/', {
          params: { id: userId }
        });
        const requestData = response.data?.requests || [];
        setRequests(requestData.map(req => req.requestId));
      } catch (error) {
        console.error('Error fetching requests:', error);
      }
    };

    if (userId) {
      fetchRequests();
    }
  }, [userId]);

  return (
    <Leaderboard>
      <Header>
        <div>Avatar</div>
        <div>Name</div>
        <div>Members</div>
        <div>Initial SWIOF</div>
        <div>Current SWIOF</div>
        <div>Performance</div>
        <div>Make Offer</div>
      </Header>
      {data.map((item) => {
        const requestSent = requests.includes(item.leaderId) || requestsI.includes(item.leaderId);

        return (
          <Row key={item.leaderId}>
            <Emblem src={item.emblem} alt={`${item.name} emblem`} />
            <PlayerEmail>{item.name}</PlayerEmail>
            <Stat>{item.members}</Stat>
            <Stat>{item.initialSWIOF}</Stat>
            <Stat>{item.initialSWIOF}</Stat>
            <Performance value="0">0%</Performance>
            <Button
              offerState={leaderId ? 'inTroop' : requestSent ? 'requestSent' : ''}
              onClick={() => !leaderId && !requestSent && onMakeOfferClick(item.leaderId)}
            >
              {leaderId ? 'YOU ARE IN A TROOP' : requestSent ? 'REQUEST SENT' : 'SEND REQUEST'}
            </Button>
          </Row>
        );
      })}
    </Leaderboard>
  );
};

export default MarketplacePlayersTable;
